/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap');
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/scrollbar';

@import '@ionic/angular/css/ionic-swiper';
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/*
@font-face {
  font-family: 'Noto Sans KR';
  src: url('./assets/fonts/NotoSansKR-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Noto Sans KR';
  src: url('./assets/fonts/NotoSansKR-thin.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: 'Noto Sans KR';
  src: url('./assets/fonts/NotoSansKR-bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'Noto Sans KR';
  src: url('./assets/fonts/NotoSansKR-light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
*/
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 100;src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format('woff2'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format('woff'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format('opentype');}

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 300;src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format('woff2'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format('woff'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format('opentype');}

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 400;src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format('woff2'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format('opentype');}

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 500;src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format('woff2'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format('opentype');}

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 700;src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format('woff2'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format('opentype');}

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 900;src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format('woff2'),url(/https:/fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format('woff'),url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format('opentype');}

  *{
    font-family: 'Noto Sans KR', sans-serif;
  }
  
  ion-toolbar {
    --border-style: none;
  }

  ion-title{
    font-weight: bold;
    font-size: 18px;
  }

  ion-back-button{
    padding-left: 17px;
    --color: #ffffff;
  }

  ion-button{
    --box-shadow:none;
  }
  
  .header-md:after{
    background-image: none;
  }
  
  .center{
      text-align:center
  }
  .gray-text{
    color: #777;
  }
  
  .empty-list{
      text-align: center;
      display: flex;
      height: 100vw;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #999999;
  }
  .chatbox{
      padding: 8px;
      border-radius: 8px;
      max-width: 70%;
      margin: 4px 0;
    }
  .chatbox p{
    margin:0;
  }
    .chatbox span{
      font-size: 12px;
    }
    .right{
      color: #444;
      float: right;
      text-align: left;
      padding-right: 5px;
    }
    .left{
      background: #eee;
      color: #444;
      float: left;
      text-align: left;
    }
    video{
      background: #eee;
      border-radius: 5px;
    }
    
  .error{
    margin:0;
    color: #F44336;
    font-size: 12px;
  }
  
  ion-text p{
    color: #777;
    margin: 4px 0px !important;
  }
  
  .header-ios ion-toolbar:last-child{
    --border-color: #000;
  }

  ion-toolbar{
    --min-height:60px;
  }

  .word {
    word-break: keep-all;
    word-wrap: break-word;
}
  ion-modal {
    @media (min-width: 700px) {
      --max-width: 390px;
    }
  }

  ion-input{
    border-radius: 10px;
    background-color: #202020;
    min-height: 50px;
    font-size: 14px;
    --placeholder-color: #595959;
    /*
    border: none;
    padding: 2px 8px !important;
    margin: 0px 0px;
    */
    

  }


  .native-input.sc-ion-input-ios {
   // border-radius: 10px;
    padding-left: 19px !important;
   // background-color: #202020;
    min-height: 50px;
  }

  .label-stacked.sc-ion-label-ios-h {
    margin-bottom: 10px;
    margin-top:0px;
    font-size: 16px;
    letter-spacing: -0.408px;
  }

  ul {
    
    padding-inline-start: 25px;
  }

  .loader-css-class {
    --background:#202020;
    --spinner-color:#117BAD;
}

ion-action-sheet.action-sheet {
  --backdrop-opacity: 0.6;
  --background: #202020;
  --button-color: #ffffff;
  --button-background-selected: #202020;
  --button-background-activated: #686868;
}

/* alert */
ion-alert.custom-alert {
  --backdrop-opacity: 0.7;
  --background: #202020;
  --min-width: 300px;
  padding: 10px;
}

.alert-sub-title.sc-ion-alert-ios {
  padding-top: 14px;
  color: #fff;
}

.custom-alert .alert-head {
  padding-top: 14px;
}

.custom-alert .alert-button-group {
  padding: 8px 16px 16px 16px;
}

button.alert-button.alert-button-cancel {
  font-size: 13px;
  font-weight: bold;
  color: #353535;
  background-color: #fff;
}

button.alert-button.alert-button-confirm {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background-color: #117BAD;
}

.md button.alert-button.alert-button-confirm {
  border-radius: 4px;
}

.ios .custom-alert button.alert-button {
  border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2)
}

.ios button.alert-button.alert-button-cancel {
  max-width: 126px;
  min-width: 40%;
  margin-right: 8px;
  border-radius: 13px;
}

.ios button.alert-button.alert-button-confirm {
  max-width: 126px;
  min-width: 40%;
  margin-left: 8px;
  border-radius: 13px;
}
/* end alert */


/* 481px 이상의 디바이스에 대한 스타일 설정 */
@media (min-width: 481px) {
  ion-app {
    max-width: 390px;
    margin: 0 auto;
  }
}

body {
  background-color: #353535;
  /* 여백 바탕 
  background-image: url('assets/images/bg.svg');
  */
  background-size:cover ;

}



swiper-container {
  --swiper-pagination-progressbar-size: 2px;
  --swiper-theme-color: #117BAD;
}

.swiper .swiper-scrollbar {
  background-color: white !important;
}

.swiper {
 
 
  --scroll-bar-background: white !important;
  --scroll-bar-background-active: #117BAD !important;
  /**
   * @prop --bullet-background: Background of the pagination bullets
   * @prop --bullet-background-active: Background of the active pagination bullet
   *
   * @prop --progress-bar-background: Background of the pagination progress-bar
   * @prop --progress-bar-background-active: Background of the active pagination progress-bar
   *
   * @prop --scroll-bar-background: Background of the pagination scroll-bar
   * @prop --scroll-bar-background-active: Background of the active pagination scroll-bar
   */
  display: block;
  user-select: none;
}

.swiper-scrollbar.swiper-scrollbar-horizontal{bottom: 0 !important; height: 3px;left:0 !important;width:100% !important}

.custom-toast {
  --background: rgb(60, 58, 58);
  --color:#fff;
}
